.strength-meter:after,
.strength-meter:before {
  border-width: 0 8px !important;
  width: 19% !important;
}

.strength-meter:before {
  left: 18.5% !important;
}

.strength-meter:after {
  right: 18.5% !important;
}
