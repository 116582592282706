.fc-theme-standard th {
  background-color: var(--fc-border-color);
  text-transform: uppercase;
}

.fc .fc-daygrid-day-top {
  justify-content: center !important;
}

.fc-scrollgrid {
  a:not(.fc-daygrid-event) {
    color: var(--ion-color-dark);
    text-transform: uppercase;
  }
}

.fc-day {
  background-color: #fff;
}

.fc-event:focus:after {
  background: rgba(30, 0, 67, 0) !important
}

.fc-daygrid-event,
.fc-timegrid-event {
  cursor: pointer;
  margin-bottom: 4px;
  border: 0 !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  :hover {
    background-color: var(--calendar-hover-background);
    color: #fff !important;
    border-radius: 4px;
  }
  &.twitter {
    background-color: var(--twitter-background);
  }
  &.facebook {
    background-color: var(--facebook-background);
  }

  &.linkedin {
    background-color: var(--linkedin-background);
  }

  &.instagram {
    background-color: var(--instagram-background);
  }

  &.instagramold {
    background-color: var(--instagram-background);
    &.error {
      background: linear-gradient(94deg, var(--instagram-background) 85%, rgb(103, 4, 4) 100%);
    }
    &:hover {
      background-color: var(--ion-background-color-6);
    }
  }
  &.fc-event-past {
    opacity: 50%;
  }
  &.timeslot {
    display: none;
    background-color: var(--ion-background-color-6);
    .fc-event-main {
      color: var(--ion-color-light-contrast);
    }
  }
}

.fc-event-main {
  padding: 4px;
  ion-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 4px;
  }
  img {
    height: 90%;
    width: auto;
    max-width: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    object-fit: fill;
    border-radius: 3px;
    &.calendar-x-logo {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 4px;
      height: 13px;
      left: 1px;
    }
  }
}

.fc-day-past{
  background: rgb(248, 248, 248) !important;
}

.fade-in-future {
  animation: fadeInFuture .3s;
  -webkit-animation: fadeInFuture .3s;
  -moz-animation: fadeInFuture .3s;
}
@keyframes fadeInFuture {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeInFuture {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeInFuture {
  0% {opacity:0;}
  100% {opacity:1;}
}

.fade-in-past {
  animation: fadeInPast .3s;
  -webkit-animation: fadeInPast .3s;
  -moz-animation: fadeInPast .3s;
}
@keyframes fadeInPast {
  0% {opacity:0;}
  100% {opacity:.5;}
}

@-moz-keyframes fadeInPast {
  0% {opacity:0;}
  100% {opacity:.5;}
}

@-webkit-keyframes fadeInPast {
  0% {opacity:0;}
  100% {opacity:.5;}
}

.loading {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.loading::before {
  content: '';
  position: absolute;
  left: -100px;
  top: 0;
  height: 100%;
  width: 100px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #d7cfdd 50%,
    transparent 100%
  );
  animation: load 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.slowload {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.slowload::before {
  content: '';
  position: absolute;
  left: -100px;
  top: 0;
  height: 100%;
  width: 100px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #d7cfdd 50%,
    transparent 100%
  );
  animation: load 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@-moz-keyframes load {
  from {
    left: -100px;
  }
  to {
    left: 100%;
  }
}

@-webkit-keyframes load {
  from {
    left: -100px;
  }
  to {
    left: 100%;
  }
}

@keyframes load {
  from {
    left: -100px;
  }
  to {
    left: 100%;
  }
}
