ion-icon {
  &.md-circle {
    background-color: var(--ion-color-primary);
    color: #ffffff;
    border-radius: 50%;
    &.icon-large {
      height: 80px;
      width: 80px;
      padding: 20px;
    }
  }
}

@media (max-width: 576px) {
  ion-icon {
    &.md-circle {
      &.icon-large {
        height: 56px;
        width: 56px;
        padding: 16px;
      }
    }
  }
}
