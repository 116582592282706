/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

$active-tab-color: var(--ion-color-primary);

@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/auth.scss';
@import './theme/toolbar.scss';
@import './theme/icons.scss';
@import './theme/card.scss';
@import './theme/form.scss';
@import './theme/password-strength.scss';
@import './theme/toast.scss';
@import './theme/table.scss';
@import './theme/modal.scss';
@import './theme/joyride.scss';
@import './theme/calendar.scss';
@import './theme/color-picker.scss';
@import './theme/ns-toggle.scss';
@import './theme/owl-picker.scss';

@import 'netsocial-lib/src/theme/search-list-popover.scss';
@import 'netsocial-lib/src/theme/mixins.scss';
@import 'netsocial-lib/src/theme/searchbar.scss';
@import 'netsocial-lib/src/theme/variables.scss';
@import 'netsocial-lib/src/theme/styles.scss';
@import 'netsocial-lib/src/theme/avatar.scss';
@import 'netsocial-lib/src/theme/tooltip.scss';
@import 'netsocial-lib/src/theme/buttons.scss';
@import 'netsocial-lib/src/theme/message-box.scss';
@import 'netsocial-lib/src/theme/item.scss';
@import 'netsocial-lib/src/theme/flex.scss';
@import 'netsocial-lib/src/theme/badge.scss';
@import 'netsocial-lib/src/theme/icon.scss';
@import 'netsocial-lib/src/theme/chip.scss';
@import '~swiper/scss';
@import '~swiper/scss/pagination';

@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';

@font-face {
  font-family: "Twemoji Mozilla";
  src: local("Twemoji Mozilla"),
    url("./assets/fonts/Twemoji.Mozilla.ttf") format("truetype");
  font-weight: normal;
}

html {
  --calendar-size: 36vh;
  --calendar-margin: -1.5em;
}

.post-dialog {
  z-index: 2;
  --overflow: initial;

  &::part(content) {
    transition: height 0.12s ease;
  }
}

.access-token-error-alert {
  z-index: 20003 !important;
}

.ngx-charts {
  .tick {
    fill: var(--ion-color-medium);
    font-weight: 500;
  }
}

ion-app.onboarding {
  > ion-router-outlet,
  .invite-modal {
    display: none;
  }
}

.country-popover {
  --max-height: 400px;
  --min-width: 372px;
}

.onboarding-dialog {
  --width: 100%;
  --height: 100%;
}

.desktop {
  display: block;
}

.mobile,
.hide {
  display: none;
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
}

.ion-rounded {
  --border-radius: 8px;
}

.title-case {
  text-transform: capitalize;
}

.sm-dialog-container {
  --background: var(--ion-color-light);
  display: flex;
  flex-direction: column;
}

.md-dialog-container {
  --background: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  height: 100%;
  ion-grid {
    overflow-y: auto;
    width: 100%;
  }
  .md-post-actions {
    font-size: 13px;
  }
}

.md-click {
  cursor: pointer;
}

.notification-list {
  notification-list {
    height: 600px;
  }
  --width: 314px;
}

.text-ellipse {
  @include text-ellipse();
}

.cookie-notification {
  --button-color: var(--ion-color-success);
  --background: var(--ion-color-dark-tint);
  --color: var(--ion-color-dark-contrast);
  --start: unset;
}

ion-label {
  text-transform: uppercase;
  font-size: 14px;
  color: RGBA(var(--ion-color-light-contrast-rgb), 0.76);
  font-weight: 600;
}

.md-input-error {
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.md-logo {
  &:focus {
    outline: 0;
  }

  cursor: pointer;

  font-size: 200px;
  height: 54px;
  &.md-auto-margin {
    margin: auto;
    display: block;
  }
}

.hr-text {
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 30px;
  position: relative;
  outline: 0;
  border: 0;
  text-align: center;
  height: 30px;
  opacity: 0.5;
  &:before {
    content: '';
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.16),
      rgba(0, 0, 0, 0.16),
      rgba(0, 0, 0, 0.16)
    );
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 16px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.993);
    font-weight: bold;
  }
}

a {
  text-decoration: none;
  font-size: 14px;
}

p {
  font-size: 14px;
  &.md-large {
    font-size: 16px;
  }
}

ion-buttons {
  ion-button {
    p {
      font-size: inherit;
    }
  }
}

.page-option {
  width: 100%;
}

.input-button {
  height: 100%;
  position: absolute;
  right: 0;
  margin: 0;
  border-radius: 0;
  --border-radius: 0;
  width: 50px;

  &ion-button {
    border-radius: 0;
  }
}

ion-list.list-md,
ion-list.list-ios {
  background: transparent;
}

.md-scroll {
  &-300 {
    min-width: -webkit-fill-available;
    overflow-y: auto;
    max-height: 300px;
    min-height: 220px;
  }
}

.ion-page {
  background: var(--ion-background-color);
}

.md-border-start {
  border-left: 1px solid RGBA(var(--ion-color-dark-rgb), 0.12);
  &.md-light {
    border-color: RGBA(var(--ion-color-light-rgb), 0.16);
  }
}

.md-border {
  border: 1px solid RGBA(var(--ion-color-dark-rgb), 0.12);
}

.md-border-end {
  border-right: 1px solid RGBA(var(--ion-color-dark-rgb), 0.12);
}

.md-border-top {
  border-top: 1px solid RGBA(var(--ion-color-dark-rgb), 0.12);
}

.md-border-bottom {
  border-bottom: 1px solid RGBA(var(--ion-color-dark-rgb), 0.12);
  &.md-light {
    border-color: RGBA(var(--ion-color-light-rgb), 0.16);
  }
}

.md-build-info {
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: left;
  }
}

.md-page {
  height: 100%;
  &.md-page {
    overflow-y: auto;
    &.md-padded {
      padding-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

ion-content {
  &.md-padded {
    --padding-top: 50px;
    --padding-start: 50px;
    --padding-end: 50px;
  }
}

.only-reschedule {
  --height: 200px;
}

// CARDS CSS
app-schedule.ion-page {
  justify-content: flex-start !important;
}

ion-card.full-width-card {
  padding: 0;

  &.new {
    --background: #f7f7f900;
    border: 1px dashed grey;
  }

  ion-card-header {
    padding: 0;
  }

  ion-item {
    margin: 0;

    ion-label {
      padding: 1em;
    }
  }
}

.performance-chart-tooltip {
  padding: 8px 0;
  font-weight: 500;

  h4 {
    margin: 0;
    font-size: 14px;
    color: RGBA(var(--ion-color-light-rgb), 0.56);
  }

  ion-row {
    margin-top: 8px;
  }

  .value {
    margin-left: 8px;
  }
}

@media (max-width: 1439px) {
  .md-page {
    &.md-page {
      &.md-padded {
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  ion-content {
    &.md-padded {
      --padding-top: 16px;
      --padding-start: 16px;
      --padding-end: 16px;
    }
  }
}

// Custom searchbox
@media (max-width: 576px) {
  .mobile {
    display: block;
    height: 100%;
  }

  .desktop {
    display: none;
  }

  .post-dialog {
    --height: 100%;
  }

  ion-content {
    &.md-page {
      &.md-padded {
        --padding-top: 50px;
        --padding-start: 50px;
        --padding-end: 50px;
      }
    }
  }
}

.md-status-circle {
  border-radius: 50%;
  background: var(--ion-color-primary);
  padding-bottom: -13px;
  height: 12px;
  width: 12px;
  min-height: 12px;
  min-width: 12px;
  display: inline-flex;

  &.light {
    background: var(--ion-color-light);
  }

  &.medium {
    background: var(--ion-color-medium);
  }

  &.success {
    background: var(--ion-color-success);
  }

  &.danger {
    background: var(--ion-color-danger);
  }

  &.warning {
    background: var(--ion-color-warning);
  }
}

@media (max-width: 576px) {
  .cookie-notification {
    font-size: 12px;
    --start: 10px !important;
    --min-width: calc(100% - 20px);
  }

  .md-page {
    &.md-page {
      &.md-padded {
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  ion-content {
    &.md-page {
      &.md-padded {
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-top: 16px;
      }
    }
  }
}

.display-flex {
  display: flex;
}

.get-the-app-dialog,
.tour-intro-dialog {
  --width: 80%;
  --height: 80%;
  --border-radius: 14px;
}

.schedule-edit-dialog {
  --height: 348px;
  --width: 50%;
}

.schedule-delete-dialog {
  --height: 250px;
  --width: 50%;
}

.tour-status-popover {
  --width: 280px;
  --offset-y: -60%;
  .popover-content {
    bottom: 0;
    top: auto !important;
  }
}

.component-popover {
  --width: 560px;
}

.component-popover-calendar {
  z-index: 1 !important;
  --width: 500px;

  &::part(content) {
    transition: top 0.12s ease;
    position: static;
  }

  @media (max-width: 500px) {
    --width: 325px;
  }
}

.post-list-calendar {
  --width: 750px;
}

ion-select-popover {
  ion-list {
    padding: 0 !important;
    ion-label {
      font-weight: 500;
      font-size: 14px !important;
    }
  }
}

.connection-issues-alert {
  .alert-message {
    white-space: pre-wrap;
  }
}

.earned-value-tooltip {
  min-width: 420px;
}

.build-version-modal {
  --width: 80%;
  --height: 50%;
}

.update-modal {
  --width: 80%;
  --height: 50%;
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  .build-version-modal {
    --width: 30%;
    --height: 50%;
  }

  .update-modal {
    --width: 30%;
    --height: 50%;
  }

  .channel-subscription-dialog {
    --height: 300px;
  }

  .owner-swap-dialog {
    --height: 320px;
    --width: 400px;
  }

  .account-state-change-dialog {
    --height: 500px;
  }

  .get-the-app-dialog,
  .tour-intro-dialog {
    --width: 630px;
    --height: 550px;
  }

  .tour-intro-dialog {
    --width: 630px;
    --height: 550px;
  }

  .schedule-delete-dialog {
    --height: 150px;
    --width: 450px;
  }

  .schedule-edit-dialog {
    --height: 250px;
    --width: 550px;

    .md-page.md-page.md-padded {
      padding-top: 25px;
    }
  }

  .edit-channel-dialog {
    --height: 700px;
  }

  .md-team-member-invite-simple {
    --height: 500px;
  }
}

.inner-banner {
  a {
    color: white !important;
    text-decoration: underline;
  }
}

ns-select-content {
  overflow-y: scroll;
}

.timezoneAlert {
  .alert-wrapper {
    width: 400px;
  }
}

.facebook-profile-alert {
  .alert-wrapper {
    max-width: 400px;
  }
}

.meta-account-alert {
  .alert-wrapper {
    min-width: 380px;
  }
}

.one-line-truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi-line-truncate {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2; // override this to change the number of lines
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scale-down {
  transform: scale(0.5);
}

.date-label {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
}

.post-textarea {
  font-family: Roboto, sans-serif, "Twemoji Mozilla";
}

:root {
  --marketplace-green: #5e9741;
  --marketplace-gray: #b8b8ba;
  --marketplace-light-gray: #ececec;
  --ion-background-color-5: #b9c0c5;
  --ion-item-opacity: 0.32;
  --field-background-color: #f4f7fc;
  --notification-item-action-bg: #17191d;
  --notification-scrollbar-bg: #a9a9a9;
  --notification-scrollbar-outline: #708090;
  --calendar-hover-background: #53458e;
}
