@media (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: var(--calendar-size);
    height: var(--calendar-size);
  }
}

@media (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-timer {
    width: var(--calendar-size);
  }
}

.owl-dt-dialog-container {
  margin: var(--calendar-margin);
}

.owl-dt-dialog {
  &.range-month,
  &.range-week,
  &.range-day {
    .owl-dt-control-button-arrow {
      display: none;
    }

    .owl-dt-control-period-button {
      z-index: -1;
    }
  }

  &.range-month,
  &.range-week,
  &.range-day {
    .owl-dt-container-buttons {
      display: none;
    }
  }
}

.owl-dt-timer-divider:after {
  bottom: 42%;
}

.owl-dt-timer-divider:before {
  top: 42%;
}

.owl-dt-timer-divider:after,
.owl-dt-timer-divider:before {
  width: 0.24em;
  height: 0.24em;
}

.owl-dt-timer-content .owl-dt-timer-input {
  font-size: 1em;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
  font-size: 14px;
  font-weight: 500;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
  font-size: 0.8em;
}

.owl-dt-calendar-table {
  .owl-dt-calendar-cell-selected {
    background-color: var(--ion-color-primary);
  }
}

.owl-dt-timer-hour12 {
  color: var(--ion-color-primary);
  .owl-dt-timer-hour12-box {
    &:hover {
      .owl-dt-control-button-content {
        background: var(--ion-color-primary);
      }
    }
  }
}

.owl-dt-container-buttons {
  color: var(--ion-color-primary);
}

.owl-dt-control-arrow-button {
  .owl-dt-control-button-content {
    height: 1.2em;
    color: RGBA(var(--ion-color-light-contrast-rgb), 0.56);
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    font-weight: bold;
    font-size: 14px;
  }
}

.owl-dt-calendar-multi-year-view {
  .owl-dt-calendar-table {
    .owl-dt-calendar-header {
      th {
        font-size: 14px;
      }
    }
  }
}
