ion-modal {
  --border-radius: 8px;
  backdrop-filter: blur(4px);
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  ion-modal {
    --width: 750px;
  }
  .md-magic-link-dialog {
    --height: 650px;
  }
}

@media (max-width: 576px) {
  .post-dialog {
    --height: 100%;
  }
}

.md-file-info-dialog {
  .modal-wrapper {
    file-info-visualizer {
      background: var(--ion-color-light-contrast);
      ion-img,
      .image-container,
      video {
        height: 100%;
        margin-top: -78px;
      }

      .image-container {
        display: flex;
        align-items: center;
      }
    }
  }
}

.instagram-custom-modal-css {
  .modal-wrapper {
    max-height: 400px!important;
    overflow: scroll;
  }
}