.hr-text {
  &:after {
    background-color: var(--ion-background-color);
  }
}

ion-grid > ion-row {
  height: 100%;
}

p {
  color: rgba(0, 0, 0, 0.56);
}

.md-auth-form {
  height: 100%;
}

.md-auth {
  max-width: 30%;
  height: 100%;

  ion-text {
    h2 {
      font-weight: bold;
    }
  }
}

.md-backdrop-art-panel {
  background-color: var(--ion-toolbar-border-color);
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  ion-img {
    width: 60%;
  }
}

.md-full {
  width: 100%;
  height: 100%;
  max-width: initial;
  .md-auth:not(.md-pricing) {
    max-width: 60%;
  }
  .md-pricing {
    max-width: 90%;
  }
}
@media (max-width: 1220px) {
  .md-auth {
    max-width: 45%;

    ion-text {
      h2 {
        font-size: 22px;
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .md-auth {
    max-width: 55%;
  }
}

@media (max-width: 576px) {
  .md-full {
    .md-auth:not(.md-pricing) {
      max-width: 100%;
    }
  }
  .md-backdrop-art-panel {
    display: none;
  }
  .md-auth {
    max-width: 100%;

    ion-text {
      h2 {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}
