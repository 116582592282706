table.cdk-table {
  width: 100%;

  cdk-row {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  ion-badge:not(.md-large) {
    margin: 4px;
    --padding-start: 8px;
    --padding-end: 8px;
    font-size: 10px;
    text-transform: uppercase;
    &[color='clear'] {
      --padding-end: 24px;
      color: var(--ion-color-primary);
      ion-icon {
        position: absolute;
        top: 0;
      }
    }
  }

  entity-avatar {
    --size: 28px;
  }

  ion-toggle {
    transform: scale(0.68);
  }

  ion-avatar {
    &.facebook,
    &.linkedin,
    &.twitter,
    &.instagram {
      padding: 6px;
    }
    height: 32px;
    width: 32px;
    min-width: 32px;
    .md-social-icon {
      height: 20px;
      width: 20px;
      padding: 3px;
    }
  }

  ion-button:not(.button-icon) {
    --padding-start: 12px;
    --padding-end: 12px;
    --border-radius: 2px;
  }

  ion-button.button-icon {
    border: 1px solid RGBA(var(--ion-color-light-contrast-rgb), 0.08);
    border-radius: 2px;
  }

  ns-toggle {
    display: inherit;
  }

  thead {
    > tr {
      background-color: transparent;
      th {
        height: 48px;
        text-align: left;
        padding-left: 16px;
        color: var(--ion-color-medium);
        white-space: nowrap;
      }
    }
  }
  tbody {
    > tr,
    > cdk-row {
      &:nth-child(odd) {
        background-color: var(--ion-background-color);
      }

      td {
        a {
          color: var(--ion-color-medium);
          font-size: 11px;
          text-decoration: underline;
          font-weight: bold;
        }

        table {
          thead {
            tr {
              th {
                font-size: 12px;
                opacity: 0.9;
                height: 36px;
                padding: 0;
                span {
                  border-top: 1px solid RGBA(var(--ion-color-medium-rgb), 0.32);
                  border-bottom: 1px solid
                    RGBA(var(--ion-color-medium-rgb), 0.32);
                  padding: 4px 16px;
                }
              }
            }
          }

          tbody {
            > tr,
            > cdk-row {
              &:nth-child(odd) {
                background-color: transparent;
              }
            }
          }
        }
      }

      td:not([no-padding]) {
        height: 48px;
        padding-left: 16px;
        font-weight: 500;
        ion-skeleton-text:not(.in-media) {
          height: 10px;
          width: 114px;
        }
      }
    }
  }
}
