ion-toggle.ns-toggle {
  --on-text: 'On';
  --off-text: 'Off';

  &.type-toggle {
    width: 142px;
  }

  transform: rotate(180deg) !important;
  --background-checked: var(--ion-color-medium);
  --background: var(--ion-color-medium);
  --handle-background-checked: var(--ion-color-tertiary);
  --border-radius: 6px;
  --handle-border-radius: 0 6px 6px 0;
  --handle-spacing: 0;
  --handle-height: 100%;
  --handle-width: 50%;
  --handle-background: var(--ion-color-warning);
  --handle-box-shadow: none;

  height: 30px;
  width: 100px;

  &::part(track) {
    &:before {
      content: var(--off-text);
      font-size: 10px;
      color: var(--ion-color-dark-contrast);
      font-weight: 600;
      position: absolute;
      top: 8px;
      left: 14px;
      transform: rotate(180deg);
      z-index: 1;
      text-transform: uppercase;
    }
  }

  &::part(track) {
    &:after {
      content: var(--on-text);
      font-size: 10px;
      color: var(--ion-color-dark-contrast);
      font-weight: 600;
      position: absolute;
      top: 8px;
      right: 18px;
      transform: rotate(180deg);
      text-transform: uppercase;
    }
  }

  &[ng-reflect-checked='false'] {
    --handle-border-radius: 6px 0px 0px 6px;
  }
}
