@import 'netsocial-lib/src/theme/mixins.scss';

*[ng-reflect-color-picker] {
  @include paper-shadow(1);
  border: 3px solid var(--ion-color-light);
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
}

color-picker {
  .color-picker.open {
    border: 1px solid var(--ion-color-light);
    border-radius: 4px;
    margin-top: -80px;
    @include paper-shadow(1);
  }
}

.color-picker .cursor {
  border: 3px solid var(--ion-color-dark) !important;
  margin-left: 5px;
  @include paper-shadow(2);
}

.color-picker .hue,
.color-picker .alpha {
  border-radius: 8px;
  box-shadow: inset 0 0 1px var(--ion-color-dark);
}

.color-picker .type-policy {
  background-size: 10px 18px !important;
  .type-policy-arrow {
    cursor: pointer;
  }
}

.color-picker .arrow.arrow-right-bottom {
  border-color: transparent var(--ion-color-light) transparent transparent !important;
}

.color-picker .saturation-lightness {
  border-radius: 4px;
  box-shadow: inset 0 0 1px var(--ion-color-dark);
}

.color-picker .hex-text .box input,
.color-picker .rgba-text .box input,
.color-picker .hsla-text .box input {
  border: 1px solid var(--ion-color-light-shade) !important;
  border-radius: 4px;
}
