ion-card {
  &.ion-padding {
    padding: 16px;
  }

  &.md-flat {
    padding: 0;
    box-shadow: none;
    --background: none;
  }
}
