.joyride-step__container {
  background-color: var(--ion-color-dark) !important;
  border-radius: 4px;
}

.joyride-arrow__bottom {
  border-top: 11px solid var(--ion-color-dark) !important;
}

.joyride-arrow__top {
  border-bottom: 11px solid var(--ion-color-dark) !important;
}

.joyride-arrow__right {
  border-left: 11px solid var(--ion-color-dark) !important;
}

.joyride-step__title,
.joyride-step__body {
  font-size: 12px !important;
  color: var(--ion-color-dark-contrast) !important;
  max-width: 280px;
}

.joyride-step__close {
  display: none;
}

.joyride-backdrop {
  backdrop-filter: blur(2px);
  background-color: RGBA(var(--ion-color-dark-rgb), 0.72) !important;
}
